import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import orders from "./slices/orderSlice";
import airport from "./slices/airportSlice";
import menu from "./slices/menuSlice";
import invoice from "./slices/invoiceSlice";
import user from "./slices/userSlice";
import history from "./slices/historySlice";
import customers from "./slices/customerSlice";
import roles from "./slices/rolesSlice";

const allReducers = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    orders,
    invoice,
    airport,
    menu,
    user,
    history,
    customers,
    roles,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

const rootReducer = (state, action) => {
  // if (action === 'auth/resetApp') {
  //   storage.removeItem("persist:root");
  //   state = undefined;
  // }
  return allReducers(state, action);
};

export default rootReducer;
