import HttpMethod, { HTTPMethod } from "http-method-enum";
import {
  SEARCH_INDEX_API_KEY,
  SEARCH_FACETS,
  PARTNER_API_BASE,
  PARTNER_API_CLIENT_ID,
  PARTNER_API_CLIENT_SECRET,
  OKTA_SCOPES,
} from "configs/AppConfig";

// const unauthorizedCode = [400, 401, 403];

async function http(request) {
  try {
    let requestOptions = {
      headers: request.header,
      method: request.method,
    };
    if (request.method === HTTPMethod.POST || request.method === HTTPMethod.PUT)
      requestOptions = {
        ...requestOptions,
        body: JSON.stringify(request.body),
      };

    const response = await fetch(request.url, requestOptions);
    const responseData = await response.json();
    return {
      header: response.headers,
      data: responseData,
      status: response.status,
    };
  } catch (error) {
    console.log(error);
  }
}

async function get(url, index) {
  try {
    const {
      currentPage,
      pageSize,
      search,
      token,
      filter,
      sortField,
      sortOrder,
    } = index.params;
    let apiUrl = `${PARTNER_API_BASE}/${url}?$count=true&${SEARCH_FACETS}`;
    let headers = {
      "api-key": SEARCH_INDEX_API_KEY,
      Authorization: `Bearer ${token}`,
    };

    if (search) apiUrl = `${apiUrl}&search=${search}`;
    if (filter) apiUrl = `${apiUrl}&$filter=${filter}`;
    if (sortField) apiUrl = `${apiUrl}&$orderby=${sortField} ${sortOrder}`;

    apiUrl = `${apiUrl}&$skip=${(currentPage - 1) * pageSize}&queryType=full`;

    const response = await fetch(apiUrl, { headers, method: index.method });
    const responseData = await response.json();
    return {
      header: response.headers,
      data: responseData,
      status: response.status,
    };
  } catch (error) {}
}

async function getToken(params) {
  try {
    let requestParams = {
      ...params,
      grant_type: "password",
      scope: OKTA_SCOPES,
    };
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Basic ${btoa(
        `${PARTNER_API_CLIENT_ID}:${PARTNER_API_CLIENT_SECRET}`
      )}`,
    };

    const encodeFormData = (data) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    };

    const response = await fetch(`${PARTNER_API_BASE}/token`, {
      method: "POST",
      headers,
      body: encodeFormData(requestParams),
    });
    const responseData = await response.json();
    return {
      ...responseData,
      status: response.status,
    };
  } catch (error) {
    console.log("Error");
  }
}

export { get, getToken, http };
