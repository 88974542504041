import { jwtDecode } from "jwt-decode";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";
import { signIn, signOut } from "./authSlice";

export const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  loading: false,
  message: "",
  showMessage: false,
  success: true,
  userId: "",
  users: [],
};

export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.getUsers(data);
      if (response.status === 200) {
        return response.data.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data.message);
      } else return initialState;
    } catch (err) {}
  }
);

export const addUser = createAsyncThunk(
  "user/addUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.addUser(data);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const editUser = createAsyncThunk(
  "user/editUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.editUser(data);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.deleteUser(data);
      if (response.status === 200) {
        console.log({
          success: response.data.success,
          message: response.data.message,
          userId: data.userId,
        });
        return {
          success: response.data.success,
          message: response.data.message,
          userId: data.userId,
        };
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      const { email, firstName, lastName, userId } = action.payload;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.userId = userId;
    },
    clearMessage: (state, action) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        const decodedToken = jwtDecode(action.payload);
        const { sub, firstName, lastName, userId } = decodedToken;
        state.email = sub;
        state.firstName = firstName;
        state.lastName = lastName;
        state.userId = userId;
      })
      .addCase(signOut.fulfilled, (state) => {
        return initialState;
      })
      .addCase(fetchUsers.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        return {
          ...state,
          users: action.payload,
          loading: false,
        };
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          message: action,
        };
      })
      .addCase(addUser.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(addUser.fulfilled, (state, action) => {
        const { success, message, data } = action.payload;
        return {
          ...state,
          users: [...state.users, data],
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(addUser.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(editUser.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(editUser.fulfilled, (state, action) => {
        const { success, message, data } = action.payload;
        const { id } = data;
        console.log(data);
        const editedUsers = state.users.map((a) => {
          if (a.id === id) {
            return data;
          } else {
            return a;
          }
        });
        return {
          ...state,
          users: editedUsers,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(editUser.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(deleteUser.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const { success, message, userId } = action.payload;
        return {
          ...state,
          users: state.users.filter((a) => a.userId !== userId),
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(deleteUser.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      });
  },
});

export const { setUserDetails, clearMessage } = userSlice.actions;

export default userSlice.reducer;
