import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";
import { signOut } from "./authSlice";

export const initialState = {
  customers: [],
  loading: false,
  message: "",
  showMessage: false,
  success: true,
};

export const fetchCustomers = createAsyncThunk(
  "customer/fetchCustomers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.getCustomers();
      if (response.status === 200) {
        return response.data.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data.message);
      } else return initialState;
    } catch (err) {}
  }
);

export const addCustomer = createAsyncThunk(
  "customer/addCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.addCustomer(data);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const editCustomer = createAsyncThunk(
  "customer/editCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.editCustomer(data);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.deleteCustomer(data);
      if (response.status === 200) {
        return {
          success: response.data.success,
          message: response.data.message,
          customerId: data.customerId,
        };
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    clearMessage: (state, action) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        return {
          ...state,
          customers: action.payload,
          loading: false,
        };
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          message: action,
        };
      })
      .addCase(addCustomer.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        const { success, message, data } = action.payload;
        return {
          ...state,
          customers: [...state.customers, data],
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(addCustomer.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(editCustomer.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        const { success, message, data } = action.payload;
        const { id } = data;
        const editedCustomers = state.customers.map((a) => {
          if (a.id === id) {
            return data;
          } else {
            return a;
          }
        });
        return {
          ...state,
          customers: editedCustomers,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(editCustomer.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(deleteCustomer.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        const { success, message, customerId } = action.payload;
        return {
          ...state,
          customers: state.customers.filter((a) => a.id !== customerId),
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(signOut.fulfilled, (state) => {
        return initialState;
      });
  },
});

export const { clearMessage } = customerSlice.actions;

export default customerSlice.reducer;
