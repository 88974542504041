import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";
import { signOut } from "./authSlice";

export const initialState = {
  roles: [],
  permissions: [],
  loading: false,
  message: "",
  showMessage: false,
  success: true,
};

export const fetchPermissions = createAsyncThunk(
  "roles/fetchPermissions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.getPermissions();
      if (response.status === 200) {
        return response.data.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data.message);
      } else return initialState;
    } catch (err) {}
  }
);

export const fetchRoles = createAsyncThunk(
    "roles/fetchRoles",
    async (data, { rejectWithValue }) => {
      try {
        const response = await ApiService.getRoles();
        if (response.status === 200) {
          return response.data.data;
        } else if (response.status === 401) {
          return rejectWithValue(response.data.message);
        } else return initialState;
      } catch (err) {}
    }
  );

export const addRole = createAsyncThunk(
  "roles/addRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.addRole(data);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const editRole = createAsyncThunk(
  "roles/editRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.editRole(data);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.deleteRole(data);
      if (response.status === 200) {
        return {
          success: response.data.success,
          message: response.data.message,
          roleId: data.roleId,
        };
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {}
  }
);

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    clearMessage: (state, action) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        return {
          ...state,
          roles: action.payload,
          loading: false,
        };
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          message: action,
        };
      })
      .addCase(fetchPermissions.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        return {
          ...state,
          permissions: action.payload,
          loading: false,
        };
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          message: action,
        };
      })
      .addCase(addRole.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(addRole.fulfilled, (state, action) => {
        const { success, message, data } = action.payload;
        return {
          ...state,
          roles: [...state.roles, data],
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(addRole.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(editRole.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(editRole.fulfilled, (state, action) => {
        const { success, message, data } = action.payload;
        const { id, name, description, permissions } = data;
        const editedRole = state.roles.map((a) => {
          if (a.id === id) {
            return {
              ...a,
              name: name,
              description: description,
              permissions: permissions,
            };
          } else {
            return a;
          }
        });
        return {
          ...state,
          roles: editedRole,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(editRole.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(deleteRole.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        const { success, message, roleId } = action.payload;
        return {
          ...state,
          roles: state.roles.filter((a) => a.id !== roleId),
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(deleteRole.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(signOut.fulfilled, (state) => {
        return initialState;
      });
  },
});

export const { clearMessage } = rolesSlice.actions;

export default rolesSlice.reducer;
